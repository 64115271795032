import {
  colorBlueMedium,
  colorErrorBase,
  colorGreen,
  colorProductAtacGex,
  colorProductCnv,
  colorRed,
  colorSteelDark,
  colorSteelDarker,
  colorSteelDarkest,
  colorSteelLight,
  colorSteelMedium,
  colorWarningBase,
  colorWhite,
} from "@10xdev/design-tokens";

// could be part of an imported theme file
export const breakPoints = {
  desktop: "900px",
  tablet: "600px",
  widescreen: "1200px",
};

export const colors = {
  base: colorSteelDarkest,
  blue: colorBlueMedium,
  blueExome: "#00B1E4",
  error: colorErrorBase,
  gray: colorSteelMedium,
  inherit: "currentColor",
  midgray: colorSteelDarker,
  purple: colorProductCnv,
  red: colorRed,
  redAtacGex: colorProductAtacGex,
  steelDark: colorSteelDark,
  steelLight: colorSteelLight,
  steelMedium: colorSteelMedium,
  success: colorGreen,
  transparent: "transparent",
  warning: colorWarningBase,
  white: colorWhite,
};
